import $ from 'jquery';
import { getCookie } from '../utils/common';
import { isValidEmail } from "../utils/forms";

const initLazyloadArticles = function () {
  $('#blogs_loadmore_button').on('click', function(e) {
    e.preventDefault();
    var ids = [];
    $('.blog-item').each(function(key, value) {
      ids.push($(this).data('id'));
    });
    var page = $('#page').val();

    $.ajax({
      type: 'POST',
      url: myAjax.ajaxurl,
      dataType: 'json',
      data: {
        action: 'blogs_loadmore',
        security: myAjax.check_nonce,
        ids: ids,
      },
      success: function(res) {
        if (res.code == 1) {
          $('.bottom-blogs').append(res.data);
          var new_page = parseInt(page) + 1;
          $('#page').val(new_page);
          var theURL = window.location.pathname;
          var path_array = theURL.split('/');

          path_array[2] = new_page;

          var new_path = path_array.join('/');

          window.history.pushState(null, null, new_path);
        }
        if (res.hidden_loading == true) {
          $('#blogs_loadmore_button').hide();
        }
      },
      error: function(e) {
        console.log('ajax error', e);
      },
    });
  });
};

const initStickyHeader = function () {
  const header__steenify = $('#main_header');
  $(window).on('scroll', function() {
    const newScroll = $(this).scrollTop();
    if (newScroll > 0) {
      header__steenify
        .addClass('header__main--white')
        .addClass('blog__header');
    } else {
      header__steenify
        .removeClass('header__main--white')
        .removeClass('blog__header');
    }
  });
};

const submitNewsletterSignup = function () {
  $('#get_subscribed').on('submit', function(e) {
    e.preventDefault();
    const form = $(this);
    const params = form.serializeArray();
    const email = form.find('.get_started__input').val();
    const messageValidate = form.find('.error-validate');
    const messageRequest = form.find('.error-request');
    const _time = new Date();
    const ld = form.find('.ld');
    let reqBody = {
      submittedAt: _time.getTime(),
      fields: params,
      context: {
        pageName: 'Joinbeam',
        pageUri: window.location.href,
      },
    };

    if (getCookie('hubspotutk')) {
      reqBody.context.hutk = getCookie('hubspotutk');
    }

    messageRequest.html('');

    if (isValidEmail(email)) {
      messageValidate.addClass('d-none');
      ld.addClass('loading');
      $.ajax({
        type: 'POST',
        url:
          'https://api.hsforms.com/submissions/v3/integration/submit/3792934/e828cd82-784d-463f-8b15-017bf50be8da',
        contentType: 'application/json',
        dataType: 'json',
        data: JSON.stringify(reqBody),
      })
        .done(function() {
          ld.removeClass('loading');
          $('#modal__subscribe').modal('hide');
          $('#thankyou__newsletter').modal('show');
          form.trigger('reset');
        })
        .fail(function(jqXHR, textStatus) {
          ld.removeClass('loading');
          if (jqXHR && jqXHR.responseText) {
            const responseText = JSON.parse(jqXHR.responseText);
            messageRequest.html(responseText.message);
          }
        });
    } else {
      messageValidate.removeClass('d-none');
    }
  });
};

const initBlogPage = function () {
  initLazyloadArticles();
  initStickyHeader();
  submitNewsletterSignup();
};

export {
  initBlogPage,
  initStickyHeader,
  submitNewsletterSignup,
};
