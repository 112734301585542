import $ from 'jquery';

const initTimelineUI = function () {
  const timeline = $('#timeline');
  let events = timeline.data('events');
  const start = parseInt(timeline.data('start'));
  const end = parseInt(timeline.data('end'));
  const endTime = (end - start + 1) * 12;
  const headings = [];

  let iYear = start;
  for (let i = 0; i < endTime; i += 12) {
    if (i + 12 >= endTime) {
      headings.push({
        start: i,
        title: iYear,
      });
    } else {
      headings.push({
        start: i,
        end: i + 12,
        title: iYear,
      });
    }

    iYear += 1;
  }

  for (let j = 0, len = events.length; j < len; j++) {
    const event = events[j];
    event.year = parseInt(event.year);
    event.month = parseInt(event.month);
    event.width = parseInt(event.width);
    event.type = parseInt(event.type);
    events[j] = event;

    const year = event.year;
    const month = event.month;
    let heading;

    for (let k = 0, len = headings.length; k < len; k++) {
      if (year === headings[k].title) {
        heading = headings[k];
      }
    }

    if (month === 1) {
      event.start = 0;
    } else {
      if (heading) {
        event.start = heading.start + month;
      }
    }
  }


  timeline.timespace({
    startTime: 0,
    endTime: endTime,
    markerWidth: 11,
    maxHeight: 200,
    data: {
      headings: headings,
      events: events,
    },
  });
};

const initAboutPage = function () {
  initTimelineUI();
};

export { initAboutPage };
