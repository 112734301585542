// import Swup from 'swup';
// import swupMergeHeadPlugin from 'swup/plugins/swupMergeHeadPlugin';
// import AOS from 'aos';
// Import css
import './scss/index.scss';

if (typeof window.Steenify === 'undefined') {
  window.Steenify = {};
}

import $ from 'jquery';
import 'scroll-depth/jquery.scrolldepth.min';
import '@fancyapps/fancybox/dist/jquery.fancybox';

import { initHomePage } from './js/home';
import { initAboutPage } from './js/about';
import { initContactPage } from './js/contact';
import { initProductPage } from './js/product';
import { initGamesPage } from './js/games';
import { initStoriesPage } from './js/stories';
import { initGameDetailsPage } from './js/game';
import { initCareersPage } from './js/careers';
import { initStoryPage } from './js/health-inner';
import { initSearchResultsPage } from './js/search';
import { initBlogPage } from './js/blog';
import { initArticlePage } from './js/article';
import { initTagsResultsPage } from './js/tags';
import { initMCDPage } from './js/mcdonalds';
import { setCookie, getCookie } from './js/utils/common';
import { isValidEmail, setupGetStartedForm } from './js/utils/forms';

const clickOrTouch = 'ontouchend' in window ? 'touchend' : 'click';

const initHeader = function () {
  const header__item = $('.header__item');
  const header__backcrop = $('.header__backcrop');

  const header__content = $('.header__content');
  const header__cta__search = $('.header__cta__search');
  const menu__mobile__arrow = $('.menu__arrow');
  const hamburger = $('.hamburger');
  const menu_mobile = $('.header__mobile-nav');

  header__item.hover(
    function () {
      const height = $(this).find('.header__child').height();
      header__backcrop.height(height + 40);
    },
    function () {
      header__backcrop.height(0);
    },
  );

  header__cta__search.on(clickOrTouch, function () {
    header__content.toggleClass('active__search');
  });

  menu__mobile__arrow.on('click', function (e) {
    e.preventDefault();
    const self = $(this);
    const liParent = self.closest('li');
    self.toggleClass('opened');
    liParent.find('.sub-menu').stop().slideToggle();
  });

  hamburger.on('click', function () {
    const self = $(this);

    self.toggleClass('opened');
    menu_mobile.toggleClass('opened');
  });
};

const initPage = function () {
  const view = $('.steenify__page').data('view');
  switch (view) {
    case 'home':
      initHomePage();
      break;
    case 'about':
      import(
        /* webpackChunkName: "jqtimespace" */ '../public/js/jquery.timespace'
      ).then(() => initAboutPage());
      break;
    case 'contact':
      initContactPage();
      break;
    case 'product':
      initProductPage();
      break;
    case 'games':
      initGamesPage();
      break;
    case 'stories':
      initStoriesPage();
      break;
    case 'game_detail':
      initGameDetailsPage();
      break;
    case 'careers':
      initCareersPage();
      break;
    case 'health-inner':
      initStoryPage();
      break;
    case 'search':
      initSearchResultsPage();
      break;
    case 'blog':
      initBlogPage();
      break;
    case 'blog-article':
      initArticlePage();
      break;
    case 'tags':
      initTagsResultsPage();
      break;
    case 'mcdonalds':
      initMCDPage();
      break;
    default:
      console.log(view);
  }
};

const submitFooterNewsletterForm = function () {
  $('.contact_form__btn').on('click', function () {
    const form = $(this).closest('form');
    const params = form.serializeArray();
    const email = form.find('#email').val();
    const messageValidate = form.find('.error-validate');
    const messageRequest = form.find('.error-request');
    const _time = new Date();
    const ld = form.find('.ld');
    let reqBody = {
      submittedAt: _time.getTime(),
      fields: params,
      context: {
        pageName: 'Joinbeam',
        pageUri: window.location.href,
      },
    };

    if (getCookie('hubspotutk')) {
      reqBody.context.hutk = getCookie('hubspotutk');
    }

    messageRequest.html('');

    if (isValidEmail(email)) {
      messageValidate.addClass('d-none');
      ld.addClass('loading');
      $.ajax({
        type: 'POST',
        url:
          'https://api.hsforms.com/submissions/v3/integration/submit/3792934/e828cd82-784d-463f-8b15-017bf50be8da',
        contentType: 'application/json',
        dataType: 'json',
        data: JSON.stringify(reqBody),
      })
        .done(function () {
          ld.removeClass('loading');
          $('#thankyou__newsletter').modal('show');
          form.trigger('reset');
        })
        .fail(function (jqXHR, textStatus) {
          ld.removeClass('loading');
          if (jqXHR && jqXHR.responseText) {
            const responseText = JSON.parse(jqXHR.responseText);
            messageRequest.html(responseText.message);
          }
        });
    } else {
      messageValidate.removeClass('d-none');
    }
  });
};
console.log('I AM RUNNI?NG THE APP');
function runApp() {
  initHeader();
  initPage();

  setTimeout(function () {
    setupGetStartedForm();
    submitFooterNewsletterForm();
  }, 8000);

  const queryString = window.location.search;
  if (
    (queryString !== '' && queryString.indexOf('utm_') >= 0) ||
    queryString.indexOf('gclid') >= 0
  ) {
    sessionStorage.setItem('qs', queryString);
  }
}

//***************************************
//      Main program
//***************************************

$(window).load(function () {
  runApp();
  if (typeof $.scrollDepth == 'function') {
    $.scrollDepth();
  }

  if (window.location.search !== '') {
    const qsObj = new URLSearchParams(window.location.search);
    let productOfInterest = qsObj.get('package_interest');
    if (productOfInterest === 'pro') {
      setCookie('product_of_interest', 'BEAM Pro', 30);
    }
  }
});
