import $ from 'jquery';
import 'owl.carousel';
import 'popper.js';
import 'bootstrap/dist/js/bootstrap.min';

const initFeaturedGamesSlider = function () {
  var owlSlider = $('.games__slider');
  owlSlider.owlCarousel({
    loop: true,
    autoWidth: true,
    items: 4,
    autoHeight: true,
    nav: true,
    stagePadding: 90,
    dots: false,
    navText: [
      '<svg xmlns="http://www.w3.org/2000/svg" width="8" height="12" viewBox="0 0 8 12"><g><g opacity=".54" transform="rotate(90 4.5 6.5)"><path d="M8.6 3.6L4 8.2-.6 3.6-2 5l6 6 6-6z"/></g></g></svg>',
      '<svg xmlns="http://www.w3.org/2000/svg" width="8" height="12" viewBox="0 0 8 12"><g><g opacity=".54" transform="rotate(90 4.5 6.5)"><path d="M8.6 3.6L4 8.2-.6 3.6-2 5l6 6 6-6z"/></g></g></svg>',
    ],
    responsive: {
      0: {
        slideBy: 1,
      },
      576: {
        slideBy: 2,
      },
      640: {
        slideBy: 2,
      },
      992: {
        slideBy: 3,
      },
    },
    onTranslate: function() {
      owlSlider.addClass('no-padding');
    },
  });
};

const initGamesCategorySlider = function () {
  $('.game-list__slider').each(function() {
    var owlSlider = $(this);

    owlSlider.owlCarousel({
      loop: true,
      autoWidth: true,
      items: 4,
      autoHeight: true,
      nav: true,
      stagePadding: 90,
      dots: false,
      navText: [
        '<svg xmlns="http://www.w3.org/2000/svg" width="8" height="12" viewBox="0 0 8 12"><g><g opacity=".54" transform="rotate(90 4.5 6.5)"><path d="M8.6 3.6L4 8.2-.6 3.6-2 5l6 6 6-6z"/></g></g></svg>',
        '<svg xmlns="http://www.w3.org/2000/svg" width="8" height="12" viewBox="0 0 8 12"><g><g opacity=".54" transform="rotate(90 4.5 6.5)"><path d="M8.6 3.6L4 8.2-.6 3.6-2 5l6 6 6-6z"/></g></g></svg>',
      ],
      responsive: {
        0: {
          slideBy: 1,
        },
        576: {
          slideBy: 2,
        },
        992: {
          slideBy: 2,
        },
        1170: {
          slideBy: 2,
        },
      },
      onTranslate: function() {
        owlSlider.addClass('no-padding');
      },
    });
  });
};

const initGameVideoControls = function () {
  const gamevideos = $('.games .video-control, .promotion .video-control');

  if (
    'ontouchstart' in window ||
    (window.DocumentTouch && document instanceof DocumentTouch)
  ) {
    gamevideos.on('touchstart', function() {
      gamevideos.find('video').trigger('pause');
      gamevideos.removeClass('played');
      const self = $(this);
      const video = $('video', self).get(0);
      self.addClass('played');
      video.play();
    });
  }

  gamevideos.on('mouseenter', function() {
    const self = $(this);
    const video = $('video', self).get(0);
    self.addClass('played');
    video.play();
  });

  gamevideos.on('mouseleave', function() {
    const self = $(this);
    const video = $('video', self).get(0);
    self.removeClass('played');
    video.pause();
  });

  $('.games .video-control__mute, .promotion .video-control__mute').on(
    'click',
    function() {
      const self = $(this);
      // const id = $(this).data('id');
      // const video = document.getElementById(id);
      const video = self.siblings('video')[0];
      self.toggleClass('un-mute');
      video.muted = !video.muted;
    }
  );
};

const initGameDetailsModal = function () {
  $(document).on('click', '.games__item__quickview', function() {
    var modal = $('#modal__game_detail');
    var item = $(this);
    var title = item.data('title');
    var mp4 = item.data('mp4');
    var logo = item.data('logo');
    var category = item.data('category');
    var ages = item.data('ages');
    var desctiption = item.data('desctiption');
    var tags = item.data('tags') ? item.data('tags').split(',') : [];

    modal.find('.game_detail__tag').remove();

    if (tags.length) {
      for (var i = 0, l = tags.length; i < l; i++) {
        modal
          .find('.game_detail__keyword')
          .append(
            ' <span class="game_detail__tag tag tag--border">' +
            tags[i] +
            '</span>',
          );
      }
    }

    modal.find('.game_detail__title').html(title);
    modal.find('.game_detail__mp4').attr('src', mp4);
    if (modal.find('.game_detail__viz').length) {
      modal.find('.game_detail__viz')[0].load();
      modal.find('.game_detail__viz').attr('poster', logo);
      modal.find('.game_detail__viz')[0].play();
    }

    modal.find('.game_detail__logo').attr('src', logo);
    modal.find('.game_detail__category').html(category);
    modal.find('.game_detail__description').html(desctiption);
    modal.find('.game_detail__number').html(ages);

    modal.modal('show');
  });
};

const initGamesLazyloadVideos = function () {
  $('.games__video__lazy').each(function(index, item) {
    const url = $(item)
      .find('source')
      .data('source');

    $(item)
      .find('source')
      .attr('src', url);

    $(item)[0].load();
  });
};

const initGamesPage = function () {
  initFeaturedGamesSlider();
  initGamesCategorySlider();
  initGameVideoControls();
  initGameDetailsModal();

  setTimeout(function() {
    initGamesLazyloadVideos();
  }, 5000);
};

export {
  initGamesPage,
  initGameDetailsModal,
  initGameVideoControls,
  initGamesLazyloadVideos
};
