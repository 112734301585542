import $ from 'jquery';
import 'slick-carousel/slick/slick.min';

const initTestimonialsSlider = function () {
  $('.testimonial__slider').slick({
    dots: true,
    arrows: false,
  });
};

const initVideoControls = function () {
  const gamevideos = $('.stories_featured .video-control');

  if (
    'ontouchstart' in window ||
    (window.DocumentTouch && document instanceof DocumentTouch)
  ) {
    gamevideos.on('touchstart', function() {
      gamevideos.find('video').trigger('pause');
      gamevideos.removeClass('played');
      const self = $(this);
      const video = $('video', self).get(0);
      self.addClass('played');
      video.play();
    });
  }

  gamevideos.on('mouseenter', function() {
    const self = $(this);
    const video = $('video', self).get(0);
    self.addClass('played');
    video.play();
  });

  gamevideos.on('mouseleave', function() {
    const self = $(this);
    const video = $('video', self).get(0);
    self.removeClass('played');
    video.pause();
  });

  $('.stories_featured .video-control__mute')
    .off('click')
    .on('click', function() {
      const self = $(this);
      // const id = $(this).data('id');

      // const video = document.getElementById(id);
      const video = self.siblings('video')[0];
      self.toggleClass('un-mute');
      video.muted = !video.muted;
    });
};

const initStoryPage = function () {
  initTestimonialsSlider();
  initVideoControls();
};

export { initStoryPage };
