import $ from 'jquery';
import 'slick-carousel/slick/slick.min';

const initQuotesSlider = function () {
  $('.qoute-slider').slick({
    dots: false,
    arrows: false,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    autoplay: true,
    autoplaySpeed: 2000,
  });
};

const initMCDPage = function () {
  initQuotesSlider();
};

export { initMCDPage };
