import $ from 'jquery';

const initCareersPage = function () {
  $('.job-board__dropdown').on('change', function() {
    $('#' + $(this).val()).trigger('click');
  });
};

window.Steenify = $.extend(Steenify, {
  init__careers: function() {
    initCareersPage();
  },
});

export { initCareersPage };
