import $ from 'jquery';
import 'slick-carousel/slick/slick.min';
import { initGameDetailsModal } from '../games/index';

const initScreensSlider = function () {
  $('.game-screenshots__slider').slick({
    slidesToShow: 3,
    infinite: true,
    arrows: true,
    dots: true,
    prevArrow:
      '<button type="button" class="slick-prev"><svg xmlns="http://www.w3.org/2000/svg" width="8" height="12" viewBox="0 0 8 12"><g><g opacity=".54" transform="rotate(90 4.5 6.5)"><path d="M8.6 3.6L4 8.2-.6 3.6-2 5l6 6 6-6z"/></g></g></svg></button>',
    nextArrow:
      '<button type="button" class="slick-next"><svg xmlns="http://www.w3.org/2000/svg" width="8" height="12" viewBox="0 0 8 12"><g><g opacity=".54" transform="rotate(90 4 6)"><path d="M8.6 2.6L4 7.2-.6 2.6-2 4l6 6 6-6z"/></g></g></svg></button>',
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: '50px',
        },
      },
      {
        breakpoint: 414,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: '30px',
        },
      },
    ],
  });
};

const initRecommendedSlider = function () {
  $('.game-recommend__slider').slick({
    slidesToShow: 4,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1,
          variableWidth: true,
        },
      },
    ],
  });
};

const initVideoControls = function () {
  const gamevideos = $(
    '.game-specs .video-control, .promotion .video-control',
  );
  if (
    'ontouchstart' in window ||
    (window.DocumentTouch && document instanceof DocumentTouch)
  ) {
    gamevideos.on('touchstart', function() {
      gamevideos.find('video').trigger('pause');
      gamevideos.removeClass('played');
      const self = $(this);
      const video = $('video', self).get(0);
      self.addClass('played');
      video.play();
    });
  }

  gamevideos.on('mouseenter', function() {
    const self = $(this);
    const video = $('video', self).get(0);
    self.addClass('played');
    video.play();
  });

  gamevideos.on('mouseleave', function() {
    const self = $(this);
    const video = $('video', self).get(0);
    self.removeClass('played');
    video.pause();
  });

  $('.game-specs .video-control__mute, .promotion .video-control__mute').on(
    'click',
    function() {
      const self = $(this);
      // const id = $(this).data('id');
      const video = self.siblings('video')[0];

      self.toggleClass('un-mute');
      video.muted = !video.muted;
    }
  );
};

const initSpecsSlider = function () {
  $('.game-specs__slider').slick({
    slidesToShow: 1,
    infinite: true,
    arrows: false,
    dots: true,
  });
};

const initGameDetailsPage = function () {
  initScreensSlider();
  initRecommendedSlider();
  initVideoControls();
  initSpecsSlider();
  initGameDetailsModal();
};

export { initGameDetailsPage };
