import $ from 'jquery';
import { initStickyHeader, submitNewsletterSignup } from '../blog';

const initExternalLinks = function () {
  $('.blog-article__inner a').each(function() {
    if (
      !(
        $(this)
          .attr('href')
          .indexOf('joinbeam.com') != -1
      )
    ) {
      $(this).attr('target', '_blank');
    }
  });
};

const initLikesSystem = function () {
  jQuery('.pp_like').click(function(e) {
    e.preventDefault();
    jQuery('.pp_like').hide();
    jQuery('.lds-facebook').show();
    var postid = jQuery(this).data('id');
    //alert(postid);
    var data = {
      action: 'like_action',
      security: myAjax.check_nonce,
      postid: postid,
    };
    jQuery.post(myAjax.ajaxurl, data, function(res) {
      var result = jQuery.parseJSON(res);
      //alert(res);
      var likes = '';
      likes = result.likecount + ' Likes';
      jQuery('.pp_like .post_like__number').text(likes);
      if (result.like == 1) {
        jQuery('.pp_like').addClass('liked');
      }
      if (result.dislike == 1) {
        jQuery('.pp_like').removeClass('liked');
      }
      jQuery('.pp_like').show();
      jQuery('.lds-facebook').hide();
    });
  });
};

const initArticlePage = function () {
  initStickyHeader();
  submitNewsletterSignup();
  initExternalLinks();
  initLikesSystem();
};

export { initArticlePage };
