import $ from 'jquery';
import 'parsleyjs';
import { setupContactForm } from "../utils/forms";

const scrollToContact = function () {
  $('[data-scroll-to]').on('click', function () {
    const self = $(this);
    const id = self.data('scroll-to');

    if (id && $(id).length) {
      $('html, body')
        .stop()
        .animate({
            scrollTop: $(id).offset().top - $('#main_header').height(),
          },
          'slow'
        );
    }
  });
};

const viewAllPhone = function () {
  $('.contact-way .item__viewall').on('click', function (e) {
    e.preventDefault();
    const self = $(this);
    const content = self.closest('.item').find('.item__more-phone');

    self.toggleClass('opened');
    content.stop().slideToggle();
  });
};

const initContactPage = function () {
  scrollToContact();
  viewAllPhone();

  setTimeout(function () {
    setupContactForm();
  }, 5000);
};

export { initContactPage };
