import $ from 'jquery';
import { getCookie } from './common';

const isValidEmail = function (value) {
  if (
    !/^[_a-z0-9-]+(\.[_a-z0-9-]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,3})$/i.test(
      value,
    )
  ) {
    return false;
  }

  return true;
};

const getPhoneType = function (t, e) {
  var n = '1' + t;
  $.ajax({
    url:
      'https://apilayer.net/api/validate?access_key=d5099f0446572170314e23051ae7a354&number=' +
      n,
    dataType: 'jsonp',
    success: function (t) {
      'string' === $.type(t.line_type)
        ? e.val(t.line_type)
        : e.val('Unrecognized phone type');
    },
  });
};

const submitHsForm = async function (params, region) {
  try {
    const sendData = await fetch(
      'https://api.hsforms.com/submissions/v3/integration/submit/3792934/0880154c-496e-4a92-9c43-c29569c3d9b3',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(params),
      },
    );

    return await sendData.json();
  } catch (err) {
    throw err;
  }
};

const getUserIP = async function () {
  let ipData = sessionStorage.getItem('ip-data');
  if (ipData) {
    ipData = JSON.parse(ipData);
    return ipData;
  } else {
    try {
      let result = {};
      const ipReq = await fetch(
        'https://api.ipify.org/?format=json&callback=?',
      );
      const ip = await ipReq.json();
      result.ip = ip.ip;

      const detailsReq = await fetch(
        `https://api.ipstack.com/${result.ip}?access_key=a3e17e6f3385b0e6b39428df05c4fffc`,
      );
      const ipDetails = await detailsReq.json();

      result.region = ipDetails.region_name.toLowerCase();
      result.lat = ipDetails.latitude;
      result.long = ipDetails.longitude;
      result.countryCode = ipDetails.country_code;

      sessionStorage.setItem('ip-data', JSON.stringify(result));

      return result;
    } catch (err) {
      throw err;
    }
  }
};

const sendToHomeTyp = function (params) {
  const sc = params.find((item) => item.name === 'original_lead_score').value;
  getUserIP()
    .then((ipDetails) => {
      console.log('country code', ipDetails.countryCode, ipDetails);
      if (ipDetails.countryCode === 'US' || ipDetails.countryCode === 'IL') {
        window.location = window.location.origin + '/b2c-thank-you';
      } else {
        if (sc) {
          window.location =
            'https://lp.beambyeyeclick.com/the-future-of-play-tgt-home-ty?fb=lead&sc=' +
            sc;
        } else {
          window.location =
            'https://lp.beambyeyeclick.com/the-future-of-play-tgt-home-ty?fb=lead&fr=true';
        }
      }
    })
    .catch((e) => console.error(e));
};

const sendToMainTyp = function (params, region) {
  const _type = params.find((item) => item.name === 'company_type').value;
  const sc = params.find((item) => item.name === 'original_lead_score').value;
  const _fname = params.find((item) => item.name === 'firstname').value;
  const _lname = params.find((item) => item.name === 'lastname').value;
  const _email = params.find((item) => item.name === 'email').value;
  const _phone = params.find((item) => item.name === 'phone').value;
  const _country = region;
  if (sc) {
    window.location =
      '/form-thank-you?_type=' +
      _type +
      '&sc=' +
      sc +
      '&_fname=' +
      _fname +
      '&_lname=' +
      _lname +
      '&_email=' +
      _email +
      '&_phone=' +
      _phone +
      '&_country=' +
      _country;
  } else {
    window.location =
      '/form-thank-you?_type=' +
      _type +
      '&_fname=' +
      _fname +
      '&_lname=' +
      _lname +
      '&_email=' +
      _email +
      '&_phone=' +
      _phone;
  }
};

const setupGetStartedForm = async function () {
  // geographic data. will be fetched async
  let _ipAddress = '';
  let _region = '';
  let _lat = '';
  let _long = '';

  // Determein the phone type
  $('#contact_phone').on('blur', function () {
    getPhoneType($(this).val(), $('#contact_phone-type'));
  });

  $('#company_type').on('change', function () {
    const self = $(this);
    const value = self.val();
    const edu_num_of_licenced_children = $(
      '#edu_num_of_licenced_children',
    ).closest('.get_started__control');
    const fec_center_exists = $('#fec_center_exists').closest(
      '.get_started__control',
    );
    const church_num_of_parishioners = $('#church_num_of_parishioners').closest(
      '.get_started__control',
    );

    switch (value) {
      case 'Early Learning Center / Education':
        edu_num_of_licenced_children.removeClass('d-none');
        fec_center_exists.addClass('d-none');
        church_num_of_parishioners.addClass('d-none');
        break;
      case 'Camp/Day Care':
        edu_num_of_licenced_children.removeClass('d-none');
        fec_center_exists.addClass('d-none');
        church_num_of_parishioners.addClass('d-none');
        break;
      case 'Family Entertainment Center':
        fec_center_exists.removeClass('d-none');
        edu_num_of_licenced_children.addClass('d-none');
        church_num_of_parishioners.addClass('d-none');
        break;
      case 'Church':
        church_num_of_parishioners.removeClass('d-none');
        edu_num_of_licenced_children.addClass('d-none');
        fec_center_exists.addClass('d-none');
        break;
      default:
        edu_num_of_licenced_children.addClass('d-none');
        fec_center_exists.addClass('d-none');
        church_num_of_parishioners.addClass('d-none');
        break;
    }
  });

  try {
    const ipDetails = await getUserIP();
    _region = ipDetails.region;
    _lat = ipDetails.lat;
    _long = ipDetails.long;
    _ipAddress = ipDetails.ip;
  } catch (e) {
    console.error('error while getting ip details', e);
  }

  $('#started-form').on('submit', async function (e) {
    e.preventDefault();
    const self = $(this);
    const isValid = self.parsley().isValid();

    self.find('.get_started__btn').addClass('submited');

    if (isValid) {
      // request body for the HubSpot AJAX request
      const params = self.serializeArray();

      let _rand = Math.floor(Math.random() * 2 + 1);
      let _time = new Date();
      // get the current time in a certain format
      let _date_now =
        _time.getHours() +
        ':' +
        _time.getMinutes() +
        ' ' +
        _time.getDate() +
        '/' +
        (_time.getMonth() + 1) +
        '/' +
        _time.getFullYear();

      params.push({
        name: 'form_submission_url',
        value: window.location.href,
      });

      params.push({
        name: 'workflow',
        value: _rand,
      });

      params.push({
        name: 'latitude',
        value: _lat,
      });

      params.push({
        name: 'longitude',
        value: _long,
      });

      const qs = sessionStorage.getItem('qs');
      const qsParams = new URLSearchParams(qs);

      let _utm_source = qsParams.get('utm_source');
      _utm_source = _utm_source ? _utm_source : '';
      params.push({
        name: 'utm_source',
        value: _utm_source,
      });

      let _lal_utm_source = qsParams.get('lal_utm_source');
      _lal_utm_source = _lal_utm_source ? _lal_utm_source : '';
      params.push({
        name: 'lal_utm_source',
        value: _lal_utm_source,
      });

      let _utm_medium = qsParams.get('utm_medium');
      _utm_medium = _utm_medium ? _utm_medium : '';
      params.push({
        name: 'utm_medium',
        value: _utm_medium,
      });

      let _utm_campaign = qsParams.get('utm_campaign');
      _utm_campaign = _utm_campaign ? _utm_campaign : '';
      params.push({
        name: 'utm_campaign',
        value: _utm_campaign,
      });

      let _utm_term = qsParams.get('utm_term');
      _utm_term = _utm_term ? _utm_term : '';
      params.push({
        name: 'utm_term',
        value: _utm_term,
      });

      let _utm_content = qsParams.get('utm_content');
      _utm_content = _utm_content ? _utm_content : '';
      params.push({
        name: 'utm_content',
        value: _utm_content,
      });

      let _utm_adset = qsParams.get('utm_adset');
      _utm_adset = _utm_adset ? _utm_adset : '';
      params.push({
        name: 'utm_adset',
        value: _utm_adset,
      });

      let _utm_ad = qsParams.get('utm_ad');
      _utm_ad = _utm_ad ? _utm_ad : '';
      params.push({
        name: 'utm_ad',
        value: _utm_ad,
      });

      let _utm_campaignid = qsParams.get('utm_campaignid');
      _utm_campaignid = _utm_campaignid ? _utm_campaignid : '';
      params.push({
        name: 'utm_campaignid',
        value: _utm_campaignid,
      });

      let _utm_campaignname = qsParams.get('utm_campaignname');
      _utm_campaignname = _utm_campaignname ? _utm_campaignname : '';
      params.push({
        name: 'utm_campaignname',
        value: _utm_campaignname,
      });

      let _utm_lp_source = qsParams.get('utm_lp_source');
      _utm_lp_source = _utm_lp_source ? _utm_lp_source : 'beam_website';

      params.push({
        name: 'utm_lp_source',
        value: _utm_lp_source,
      });

      let productOfInterest = getCookie('product_of_interest');
      productOfInterest =
        productOfInterest !== 'BEAM Pro' ? 'BEAM' : productOfInterest;

      params.push({
        name: 'product_of_interest_integration',
        value: productOfInterest,
      });

      let reqBody = {
        submittedAt: _time.getTime(),
        fields: params,
        context: {
          pageName: 'new joinbeam',
          ipAddress: _ipAddress,
        },
        skipValidation: true,
      };

      if (getCookie('hubspotutk')) {
        reqBody.context.hutk = getCookie('hubspotutk');
      }

      // request body for our internal service request
      const _comments = params.find((item) => item.name === 'comments').value;
      const _edu_licenced_kids = params.find(
        (item) => item.name === 'edu_num_of_licenced_children',
      ).value;
      const _fec_exists = params.find(
        (item) => item.name === 'fec_center_exists',
      ).value;
      const _church_num_of_parishioners = params.find(
        (item) => item.name === 'church_num_of_parishioners',
      ).value;
      const _interested_to_get_beam = params.find(
        (item) => item.name === 'interested_to_get_beam',
      ).value;

      const scoreBodyData = {
        'IP Region': _region,
        'First Source Detail_x': null,
        'Lead Created Date_x': _date_now,
        Latitude: _lat,
        Longitude: _long,
        'Customer Comments': _comments,
        'Edu Licenced Kids': _edu_licenced_kids,
        'Fec Exists': _fec_exists,
        'Church Num Of Parishioners': _church_num_of_parishioners,
        'Interested To Get Beam': _interested_to_get_beam,
      };

      self.find('[data-score-title]').each(function () {
        const scoreField = $(this);

        scoreBodyData[scoreField.data('score-title')] = scoreField.val();
      });

      try {
        // const kissScore = await fetchKISSScore(scoreBodyData);
        // reqBody.fields.push({
        //   name: 'original_lead_score',
        //   value: kissScore
        // });

        try {
          await submitHsForm(reqBody, _region);

          const company_type = params.find(
            (item) => item.name === 'company_type',
          ).value;

          if (company_type == 'I want this for my home') {
            sendToHomeTyp(params);
          } else {
            sendToMainTyp(params, _region);
          }
        } catch (err) {
          console.error('error while submitting the form', err);
          $('#started-form').find('.get_started__btn').removeClass('submited');
        }
      } catch (err) {
        console.error('error while submitting the form', err);
        const i = reqBody.fields.findIndex(
          (item) => item.name === 'original_lead_score',
        );

        if (i) {
          reqBody.fields[i].value = '';
        }

        try {
          const company_type = params.find(
            (item) => item.name === 'company_type',
          ).value;

          if (company_type == 'I want this for my home') {
            sendToHomeTyp(params);
          } else {
            sendToMainTyp(params, _region);
          }
        } catch (err) {
          console.error('could not submit form', err);
        }
      }
    }
  });

  $('#modal__get_started').on('hidden.bs.modal', function () {
    $('#started-form').parsley().reset();
  });
};

const fetchKISSScore = async function (reqBody) {
  try {
    const kissReq = await fetch(
      'https://score.appsforbeam.com/get_lead_quality_score_kiss',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(reqBody),
      },
    );
    const kissScore = kissReq.json();
    return kissScore;
  } catch (err) {
    throw err;
  }
};

const setupContactForm = async function () {
  console.log('hello from setupContactForm');
  // geographic data. will be fetched async
  let _ipAddress = '';
  let _region = '';
  let _lat = '';
  let _long = '';

  // Determein the phone type
  $('#contact_phone').on('blur', function () {
    getPhoneType($(this).val(), $('#contact_phone-type'));
  });

  $('#contact_company_type').on('change', function () {
    const self = $(this);
    const value = self.val();
    const edu_num_of_licenced_children = $(
      '#contact_edu_num_of_licenced_children',
    ).closest('.get_started__control');
    const fec_center_exists = $('#contact_fec_center_exists').closest(
      '.get_started__control',
    );
    const church_num_of_parishioners = $(
      '#contact_church_num_of_parishioners',
    ).closest('.get_started__control');

    switch (value) {
      case 'Early Learning Center / Education':
        edu_num_of_licenced_children.removeClass('d-none');
        fec_center_exists.addClass('d-none');
        church_num_of_parishioners.addClass('d-none');
        break;
      case 'Camp/Day Care':
        edu_num_of_licenced_children.removeClass('d-none');
        fec_center_exists.addClass('d-none');
        church_num_of_parishioners.addClass('d-none');
        break;
      case 'Family Entertainment Center':
        fec_center_exists.removeClass('d-none');
        edu_num_of_licenced_children.addClass('d-none');
        church_num_of_parishioners.addClass('d-none');
        break;
      case 'Church':
        church_num_of_parishioners.removeClass('d-none');
        edu_num_of_licenced_children.addClass('d-none');
        fec_center_exists.addClass('d-none');
        break;
      default:
        edu_num_of_licenced_children.addClass('d-none');
        fec_center_exists.addClass('d-none');
        church_num_of_parishioners.addClass('d-none');
        break;
    }
  });

  try {
    const ipDetails = await getUserIP();
    _region = ipDetails.region;
    _lat = ipDetails.lat;
    _long = ipDetails.long;
    _ipAddress = ipDetails.ip;
  } catch (e) {
    console.error('error while getting ip details', e);
  }

  $('#contact_form').on('submit', async function (e) {
    e.preventDefault();
    const self = $(this);
    const isValid = self.parsley().isValid();

    self.find('.contact-form__btn').addClass('submited');

    if (isValid) {
      // request body for the HubSpot AJAX request
      const params = self.serializeArray();

      let _rand = Math.floor(Math.random() * 2 + 1);
      let _time = new Date();
      // get the current time in a certain format
      let _date_now =
        _time.getHours() +
        ':' +
        _time.getMinutes() +
        ' ' +
        _time.getDate() +
        '/' +
        (_time.getMonth() + 1) +
        '/' +
        _time.getFullYear();

      params.push({
        name: 'form_submission_url',
        value: window.location.href,
      });

      params.push({
        name: 'workflow',
        value: _rand,
      });

      params.push({
        name: 'latitude',
        value: _lat,
      });

      params.push({
        name: 'longitude',
        value: _long,
      });

      const qs = sessionStorage.getItem('qs');
      const qsParams = new URLSearchParams(qs);

      let _utm_source = qsParams.get('utm_source');
      _utm_source = _utm_source ? _utm_source : '';
      params.push({
        name: 'utm_source',
        value: _utm_source,
      });

      let _lal_utm_source = qsParams.get('lal_utm_source');
      _lal_utm_source = _lal_utm_source ? _lal_utm_source : '';
      params.push({
        name: 'lal_utm_source',
        value: _lal_utm_source,
      });

      let _utm_medium = qsParams.get('utm_medium');
      _utm_medium = _utm_medium ? _utm_medium : '';
      params.push({
        name: 'utm_medium',
        value: _utm_medium,
      });

      let _utm_campaign = qsParams.get('utm_campaign');
      _utm_campaign = _utm_campaign ? _utm_campaign : '';
      params.push({
        name: 'utm_campaign',
        value: _utm_campaign,
      });

      let _utm_term = qsParams.get('utm_term');
      _utm_term = _utm_term ? _utm_term : '';
      params.push({
        name: 'utm_term',
        value: _utm_term,
      });

      let _utm_content = qsParams.get('utm_content');
      _utm_content = _utm_content ? _utm_content : '';
      params.push({
        name: 'utm_content',
        value: _utm_content,
      });

      let _utm_adset = qsParams.get('utm_adset');
      _utm_adset = _utm_adset ? _utm_adset : '';
      params.push({
        name: 'utm_adset',
        value: _utm_adset,
      });

      let _utm_ad = qsParams.get('utm_ad');
      _utm_ad = _utm_ad ? _utm_ad : '';
      params.push({
        name: 'utm_ad',
        value: _utm_ad,
      });

      let _utm_campaignid = qsParams.get('utm_campaignid');
      _utm_campaignid = _utm_campaignid ? _utm_campaignid : '';
      params.push({
        name: 'utm_campaignid',
        value: _utm_campaignid,
      });

      let _utm_campaignname = qsParams.get('utm_campaignname');
      _utm_campaignname = _utm_campaignname ? _utm_campaignname : '';
      params.push({
        name: 'utm_campaignname',
        value: _utm_campaignname,
      });

      let _utm_lp_source = qsParams.get('utm_lp_source');
      _utm_lp_source = _utm_lp_source ? _utm_lp_source : 'beam_website';

      params.push({
        name: 'utm_lp_source',
        value: _utm_lp_source,
      });

      let productOfInterest = getCookie('product_of_interest');
      productOfInterest =
        productOfInterest !== 'BEAM Pro' ? 'BEAM' : productOfInterest;

      params.push({
        name: 'product_of_interest_integration',
        value: productOfInterest,
      });

      let reqBody = {
        submittedAt: _time.getTime(),
        fields: params,
        context: {
          pageName: 'new joinbeam',
          ipAddress: _ipAddress,
        },
        skipValidation: true,
      };

      if (getCookie('hubspotutk')) {
        reqBody.context.hutk = getCookie('hubspotutk');
      }

      const _comments = params.find((item) => item.name === 'comments').value;
      const _edu_licenced_kids = params.find(
        (item) => item.name === 'edu_num_of_licenced_children',
      ).value;
      const _fec_exists = params.find(
        (item) => item.name === 'fec_center_exists',
      ).value;
      const _church_num_of_parishioners = params.find(
        (item) => item.name === 'church_num_of_parishioners',
      ).value;
      const _interested_to_get_beam = params.find(
        (item) => item.name === 'interested_to_get_beam',
      ).value;
      // request body for our internal service request
      const scoreBodyData = {
        'IP Region': _region,
        'First Source Detail_x': null,
        'Lead Created Date_x': _date_now,
        Latitude: _lat,
        Longitude: _long,
        'Customer Comments': _comments,
        'Edu Licenced Kids': _edu_licenced_kids,
        'Fec Exists': _fec_exists,
        'Church Num Of Parishioners': _church_num_of_parishioners,
        'Interested To Get Beam': _interested_to_get_beam,
      };

      self.find('[data-score-title]').each(function () {
        const scoreField = $(this);

        scoreBodyData[scoreField.data('score-title')] = scoreField.val();
      });

      try {
        // const kissScore = await fetchKISSScore(scoreBodyData);
        // reqBody.fields.push({
        //   name: 'original_lead_score',
        //   value: kissScore
        // });

        try {
          await submitHsForm(reqBody, _region);

          const company_type = params.find(
            (item) => item.name === 'company_type',
          ).value;

          if (company_type == 'I want this for my home') {
            sendToHomeTyp(params);
          } else {
            sendToMainTyp(params, _region);
          }
        } catch (err) {
          console.error('error while submitting the form', err);
          $('#contact_form').find('.contact-form__btn').removeClass('submited');
        }
      } catch (err) {
        console.error('error while submitting the form', err);
        const i = reqBody.fields.findIndex(
          (item) => item.name === 'original_lead_score',
        );

        if (i) {
          reqBody.fields[i].value = '';
        }

        try {
          const company_type = params.find(
            (item) => item.name === 'company_type',
          ).value;

          if (company_type == 'I want this for my home') {
            sendToHomeTyp(params);
          } else {
            sendToMainTyp(params, _region);
          }
        } catch (err) {
          console.error('could not submit form', err);
        }
      }
    }
  });

  $('#contact_modal__get_started').on('hidden.bs.modal', function () {
    $('#contact_form').parsley().reset();
  });
};

export {
  isValidEmail,
  getPhoneType,
  submitHsForm,
  sendToMainTyp,
  sendToHomeTyp,
  setupContactForm,
  setupGetStartedForm,
  getUserIP,
};
