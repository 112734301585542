import $ from 'jquery';
import { initGameVideoControls, initGameDetailsModal } from '../games';

const showBlogItemResult = function () {
  $('#search_blogs_loadmore').on('click', function (e) {
    e.preventDefault();
    var search = $('#search_query').data('id');
    // fn render item
    var renderItem = function (item) {
      return `
          <div class="col-6 col-md-4 col-lg-3 col-lg-5th">
            <div class="article_item search__result">
              <div class="article_item__img">
                <a class="article_item__link" href="${item.link}">
                  <img src="${item.featured_image}" alt="banner_games_mb">
                </a>
              </div>
              <a href="${item.link}" class="article_item__blog">
              ${item.metadata.filter_type}
              </a>
              <a href="${item.link}" class="article_item__title">
              ${item.title.rendered}
              </a>
            </div>
          </div>
        `;
    };
    // get current page
    var currPage = parseInt($(this).attr('data-curr-page')) || 2;
    // call ajax
    $.ajax({
      url: '/wp-json/wp/v2/posts?search=' +
        search +
        '&page=' +
        currPage +
        '&per_page=5',
      success: function (res) {
        res.forEach(function (item) {
          var html = renderItem(item);
          $('#search-blog-list').append(html);
        });
      },
    });
    $(this).attr('data-curr-page', currPage + 1);
    var totalPage = parseInt($(this).attr('data-total-page')) || 1;
    if (currPage >= totalPage) {
      $(this).hide();
    }
  });
};

const showGameItemResult = function () {
  $('#search_games_loadmore').on('click', function (e) {
    e.preventDefault();
    var search = $('#search_query').data('id');
    // fn render item
    var renderItem = function (item) {
      var firstTag = '';
      if (item.game_category) {
        firstTag = item.game_category;
      }
      var video = '';
      if (item.metadata.video__mp4_ && item.metadata.video__mp4_.url) {
        video = item.metadata.video__mp4_.url;
      }
      return `
          <div class="col-6 col-md-4 col-lg-3 col-lg-5th">
              <div class="game_item search__result">
                <div class="game_item__img">
                  <a class="game_item__link games__item__quickview" href="javascript:void(0)"
                  title="${item.title.rendered}"
                  data-title="${item.title.rendered}"
                  data-mp4="${video}"
                  data-logo="${item.featured_image}"
                  data-category="${firstTag}"
                  data-ages="${item.score || ''}"
                  data-desctiption="${item.content.rendered}"
                  data-tags=""
                  >
                    <img src="${item.featured_image}" alt="banner_games_mb">
                  </a>
                </div>
                ${
        firstTag
          ? `<div class="game_item__tag">${firstTag}</div>`
          : ''
      }
              </div>
            </div>
        `;
    };
    // get current page
    var currPage = parseInt($(this).attr('data-curr-page')) || 2;
    // call ajax
    $.ajax({
      url: '/wp-json/wp/v2/game?search=' +
        search +
        '&page=' +
        currPage +
        '&per_page=5',
      success: function (res) {
        res.forEach(function (item) {
          var html = renderItem(item);
          $('#search-game-list').append(html);
        });
      },
    });
    $(this).attr('data-curr-page', currPage + 1);
    var totalPage = parseInt($(this).attr('data-total-page')) || 1;
    if (currPage >= totalPage) {
      $(this).hide();
    }
  });
};

const showStoryItemResult = function () {
  $('#search_stories_loadmore').on('click', function (e) {
    e.preventDefault();
    var search = $('#search_query').data('id');
    // fn render item
    var renderItem = function (item) {
      var firstTag = '';
      if (item.tags.length > 0) {
        firstTag = item.tags[0].name;
        firstTag = firstTag.charAt(0).toUpperCase() + firstTag.slice(1);
      }
      return (
        `
          <div class="col-6 col-md-4 col-lg-3 col-lg-5th">
              <div class="story_item search__result">
                <div class="story_item__wrapper">
                  <div class="story_item__item">
                  <a class="story_item__link" href="` +
        item.link +
        `">
                    <img class="story_item__img" src="` +
        item.featured_image +
        `"
                         alt="stories__banner">
                    <div class="story_item__content">
                      <strong class="story_item__cat">` +
        item.terms[0].name +
        `
                      </strong>
                      <h4 class="story_item__title">` +
        item.title.rendered +
        `
                      </h4>
                    </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
        `
      );
    };
    // get current page
    var currPage = parseInt($(this).attr('data-curr-page')) || 2;
    // call ajax
    $.ajax({
      url: '/wp-json/wp/v2/story?search=' +
        search +
        '&page=' +
        currPage +
        '&per_page=5',
      success: function (res) {
        res.forEach(function (item) {
          var html = renderItem(item);
          $('#search-story-list').append(html);
        });
      },
    });
    $(this).attr('data-curr-page', currPage + 1);
    var totalPage = parseInt($(this).attr('data-total-page')) || 1;
    if (currPage >= totalPage) {
      $(this).hide();
    }
  });
};

const initSearchResultsPage = function () {
  showBlogItemResult();
  showGameItemResult();
  showStoryItemResult();
  initGameVideoControls();
  initGameDetailsModal();
};

export { initSearchResultsPage };
