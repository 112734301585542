import $ from 'jquery';
import 'slick-carousel/slick/slick.min';
const Typed = require('typed.js');

import { initGamesLazyloadVideos } from '../games/index';

const initTypedAnimation = function () {
  setTimeout(function () {
    if ($('#typed').length) {
      const typed5 = new Typed('#typed', {
        strings: ['any space', 'any space', 'any space', 'any space'],
        typeSpeed: 50,
        backSpeed: 100,
        cursorChar: '|',
        shuffle: true,
        backDelay: 3000,
        smartBackspace: false,
        loop: true,
      });
    }
  }, 5000);
};

const quotesSlider = function () {
  $('.quotes-testimonials').slick({
    fade: true,
    dots: true,
    arrows: false,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  });
};

const logosSlider = function () {
  $('.logos__slider').slick({
    variableWidth: true,
    arrows: false,
    slidesToScroll: 3,
    infinite: true,
  });
  $('.logos__slider')
    .find('div a')
    .on('click', function (e) {
      e.preventDefault();
    });
};

const gamesSlider = function () {
  $('.games__slider').slick({
    arrows: false,
    dots: true,
    variableWidth: true,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
          centerPadding: '60px',
          variableWidth: false,
        },
      },
      {
        breakpoint: 568,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
          centerPadding: '30px',
          variableWidth: false,
        },
      },
    ],
  });
};

const testimonialsSlider = function () {
  $('.testimonials__slider').slick({
    arrows: false,
    dots: true,
  });
};

const testimonialsVideos = function () {
  const gamevideos = $('.testimonials .item--video');

  if (
    'ontouchstart' in window ||
    (window.DocumentTouch && document instanceof DocumentTouch)
  ) {
    gamevideos.on('touchstart', function () {
      gamevideos.find('video').trigger('pause');
      gamevideos.removeClass('played');
      const self = $(this);
      const video = $('video', self).get(0);
      video.play();
      self.addClass('played');
    });
  }

  gamevideos.on('mouseenter', function () {
    const self = $(this);
    const video = $('video', self).get(0);
    video.play();
    self.addClass('played');
  });

  gamevideos.on('mouseleave', function () {
    const self = $(this);
    const video = $('video', self).get(0);
    self.removeClass('played');
    video.pause();
  });

  $('.testimonials .item__mute').on('click', function () {
    const self = $(this);
    // const id = $(this).data('id');
    // const video = document.getElementById(id);
    const video = self.siblings('video')[0];
    self.toggleClass('un-mute');
    video.muted = !video.muted;
  });
};

const initFiguresCounter = function () {
  const counter = $('.counter');
  const couterNumber = counter.find('.counter-number');
  $(window).scroll(function () {
    if (!counter.hasClass('counted')) {
      const scroll = $(window).scrollTop();
      if (
        counter &&
        counter.length &&
        scroll >= counter.offset().top - $('#main_header').height()
      ) {
        counter.addClass('counted');
        const intervals = [];
        // count number

        couterNumber.each(function (i) {
          intervals.push(i);
          const self = $(this);
          let start = self.data('start');
          const end = self.data('end');
          const step = self.data('step');

          intervals[i] = setInterval(function () {
            if (start < end) {
              start += step;
              self.html(start.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','));
            } else {
              self.html(end.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','));
              clearInterval(intervals[i]);
            }
          }, 50);
        });
      }
    }
  });
};

const initHomePage = function () {
  initTypedAnimation();
  quotesSlider();
  logosSlider();
  gamesSlider();
  testimonialsSlider();
  testimonialsVideos();
  initFiguresCounter();
  setTimeout(function () {
    initGamesLazyloadVideos();
  }, 5000);
};

export { initHomePage, initFiguresCounter };
