import $ from 'jquery';
import { initFiguresCounter } from '../home';

if (typeof window.Steenify === 'undefined') {
  window.Steenify = {};
}

const toggleFAQItems = function () {
  $('.item__title').on('click', function() {
    const self = $(this);
    const item = self.closest('.item');
    const content = item.find('.item__desc');

    content.stop().slideToggle();
  });
};

const initProductPage = function () {
  toggleFAQItems();
  initFiguresCounter();
};

export { initProductPage };
